<template>
    <div class="display-block">
        <h3>Plan Details</h3>
        <v-card>
            <v-card-text>
                <v-layout>
                    <v-flex xs12 sm6>
                        <row-item label="Name  " :value="plan.title"></row-item>
                        <row-item label="Amount  " :value="plan.amount"></row-item>
                        <row-item label="Interval  " :value="plan.interval"></row-item>
                        <row-item label="Frequency  " :value="plan.frequency"></row-item>
                    </v-flex>
                    <v-flex xs12 sm6 text-right v-if="plan?.allowedModules?.length > 0">
                        <modules-list :allowedModules="plan.allowedModules"></modules-list>
                    </v-flex>
                </v-layout>
            </v-card-text>
            <v-flex text-right>
                <v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn>
            </v-flex>
        </v-card>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import RowItem from '@/components/RowItem'
import ModulesList from '../../../components/ModulesList.vue'
export default {
    components: {
        RowItem,
        ModulesList,
    },
    data() {
        return {
            id: 0,
            items: [],
            plan: {
                id: "",
                name: "",
                cost: 0,
                commission: 0,
                validity: "",
                negativeLimit: 0
            },
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.plan = await this.getItem(appConstants.PLANS_API + "/" + this.id)
        },
    }
}
</script>
